var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void()" },
                    on: {
                      click: function($event) {
                        return _vm.refreshMeetingRoomsTable()
                      }
                    }
                  },
                  [
                    _c("h1", [
                      _c("i", {
                        staticClass: "bx bx-rotate-right",
                        staticStyle: { float: "right" }
                      })
                    ])
                  ]
                ),
                _vm.$can("create_administration_meeting_room")
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "base.infrastructures.meeting_rooms.new" }
                        }
                      },
                      [
                        _c("b-button", { attrs: { variant: "primary" } }, [
                          _vm._v("Nouvelle salle")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "row mt-4" }, [
                  _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "dataTables_length",
                        attrs: { id: "tickets-table_length" }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "d-inline-flex align-items-center" },
                          [
                            _vm._v(" Show "),
                            _c("b-form-select", {
                              attrs: { size: "sm", options: _vm.pageOptions },
                              model: {
                                value: _vm.perPage,
                                callback: function($$v) {
                                  _vm.perPage = $$v
                                },
                                expression: "perPage"
                              }
                            }),
                            _vm._v(" entries ")
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "dataTables_filter text-md-right",
                        attrs: { id: "tickets-table_filter" }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "d-inline-flex align-items-center" },
                          [
                            _vm._v(" Search: "),
                            _c("b-form-input", {
                              staticClass: "form-control form-control-sm ml-2",
                              attrs: {
                                type: "search",
                                placeholder: "Recherche..."
                              },
                              model: {
                                value: _vm.filter,
                                callback: function($$v) {
                                  _vm.filter = $$v
                                },
                                expression: "filter"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "table-responsive mb-0" },
                  [
                    _c("meetingRoomsTable", {
                      attrs: { meetingRooms: _vm.meetingRooms }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "dataTables_paginate paging_simple_numbers float-right"
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "pagination pagination-rounded mb-0" },
                          [
                            _c("b-pagination", {
                              attrs: {
                                "total-rows": _vm.rows,
                                "per-page": _vm.perPage
                              },
                              model: {
                                value: _vm.currentPage,
                                callback: function($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }